// =============================================================================
// Forms
// =============================================================================

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
	height: 38px;
	padding: 6px 10px;
	background-color: #fff;
	border: 1px solid #D1D1D1;
	border-radius: 4px;
	box-shadow: none;
	box-sizing: border-box;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
}

textarea {
	min-height: 65px;
	padding-top: 6px;
	padding-bottom: 6px;
}

input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
textarea:focus,
select:focus {
	border: 1px solid get-color(neutral, dark);
	outline: 0;
}

label {
	display: block;
	margin: .5em 0;
	font-weight: 600;
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
	display: block;
	padding: $spacing-s $spacing;
	margin: .5em 0;
	background: get-color(brand, primary);
	color: #fff;
	border-radius: $border-radius;
	transition: (all 0.2s linear);
	border: 0;
}

input[type="submit"].center,
input[type="reset"].center,
input[type="button"].center {
	margin: 0 auto;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
	background: get-color(neutral, base);
	text-decoration: none;
	color: #fff;
}

 .required {
	 color: #BF5D58;
 }
