// =============================================================================
// Images
// =============================================================================

img {
	max-width: 100%;
	vertical-align: middle;
}

.gm-style img,
img[width],
img[height] {
	max-width: none;
}
