// =============================================================================
// Variables
// =============================================================================
$debug:	false;
$responsive-helper: false;

$spacing: 16px;
$spacing-s: $spacing/2;
$spacing-xs: $spacing/3;
$spacing-l: $spacing*2;
$spacing-xl: $spacing*3;
$spacing-xxl: $spacing*4;

$border-radius: 4px;
$border-radius-large: 32px;



// =============================================================================
// Variables - Colors
// =============================================================================
$colors: (
	brand: (
		primary: #00328d,
		secondary: #70C1B3,
		tertiary: #B2DBBF,
		highlight: #FF1654
	),
	neutral: (
		base: #ddd,
		dark: #6a6a6a,
		light: #efefef
	),
	text: (
		base: #333,
		dark: #181822,
		reversed: #fff,
		link: #3366cc,
		hover: #003451
	),
	background: (
		base: #ebebeb,
		tint: #f2f2f2,
		dark: #222,
		dark-tint: #2a2a2a
	),
	global: (
		header: #323232,
		footer: #323232
	),
	social: (
		facebook: #3B5998,
		twitter: #1DA1F2,
		instagram: #262626,
		youtube: #cc181e,
		linkedin: #0077B5
	)
);



// =============================================================================
// Variables - Typography
// =============================================================================
$font-stacks: (
	display: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
	body: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
	caption: '"Helvetica Neue", Helvetica, Arial, sans-serif',
	monospace: 'Menlo, "Bitstream Vera Sans Mono", "DejaVu Sans Mono", Monaco, Consolas, monospace'
);

$font-weights: (
	light: 300,
	normal: 400,
	bold: 700,
	heavy: 900
);

// Keys should match those in $font-stacks
$font-scales: (
	display:
	(
		1: 16px,
		2: 18px,
		3: 21px,
		4: 20px,
		5: 22px,
		6: 22px,
		7: 42px,
		8: 56px
	),
	body: (1: 12px, 2: 14px, 3: 16px),
	caption: (1: 12px, 2: 14px, 3: 16px),
	monospace: (1: 14px, 2: 16px)
);



// =============================================================================
// Variables - Z-scale
// =============================================================================
$z-scale: (
	foreground: 1,
	overlay: 10,
	navigation: 100,
	modal: 200
);



// =============================================================================
// Variables - Responsive
// =============================================================================
$enable-flex: true;
$enable-grid-classes: true;

// Grid breakpoints
// Define the minimum dimensions at which your layout will change, adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
) !default;

// Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px
) !default;

// Grid columns
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
