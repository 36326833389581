// =============================================================================
// Links
// =============================================================================

a {
	color: get-color(text, link);
	text-decoration: none;
}

a:focus,
a:hover {
	color: get-color(text, hover);
	text-decoration: underline;
}
