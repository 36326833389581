// =============================================================================
// Paragraphs
// =============================================================================

p {
	margin-bottom: 1.4em;
	margin-top: 0;
}

p.lead {
	font-size: 120%;
}
