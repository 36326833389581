// =============================================================================
// Buttons
// =============================================================================

.btn {
	display: inline-block;
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 4px 12px;
	font-weight: bold;
	font-size: 12px;
	text-align: center;
	cursor: pointer;

	&:hover, &:focus {
		background-color: #eee;
		outline: 0;
		text-decoration: none;
	}

	img {
		height: 9px;
		position: relative;
		top: -1px;
		margin-right: 2px;
	}
}
