// =============================================================================
// Layout
// =============================================================================

.wrapper {
	background: #fff;
	padding: 0;
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.20);
}

.page-header {
	padding: $spacing-l;
}

.page-main {
	padding: 0 $spacing-l;
}

.page-hero {
	background: #002f76;
	margin-left: -$spacing-l;
	margin-right: -$spacing-l;
	padding: $spacing-l;
	margin-bottom: $spacing-l;

	h1 {
		color: #fff;
		margin: 0;
	}
}

.page-header__btns {
	margin-top: 1em;

	@include media-breakpoint-up(sm) {
		float: right;
		margin-left: 1em;
		margin-top: 0;
	}
}




.page-footer {
	background: #e9e9e9;
	text-align: center;
	padding: 15px 10px;
	margin-top: $spacing-l;

	p {
		margin: 0;
		font-size: 12px;
	}
}

.terms {
	padding-left: 1em;
	> li {
		padding-left: 10px;
		margin-bottom: 2em;
	}

	ul  {
		list-style-type: disc;
	}
}