// =============================================================================
// List
// =============================================================================

ol, ul {
	padding-left: 1.5em;
}

dt {
	margin-top: $spacing;
	font-weight: bold;
}

dd {
	margin-left: 0;
}
