// =============================================================================
// Headings
// =============================================================================

h1, h2, h3, h4, h5, h6 {
    font-family: font-stack(display);
    color: get-color(text, base);
    text-rendering: optimizelegibility;
	margin-bottom: .7em;
	margin-top: 0;
    font-weight: font-weight(bold);
    line-height: 1.3;
}

* + h1, * + h2, * + h3, * + h4, * + h5, * + h6, * + .h1, * + .h2, * + .h3, * + .h4, * + .h5, * + .h6 {
	margin-top: 1.4em;
}

h1, .h1 { font-size: font-scale(display, 4); }
h2, .h2 { font-size: font-scale(display, 3); }
h3, .h3 { font-size: font-scale(display, 2); }
h4, .h4 { font-size: font-scale(display, 1); }
h5, .h5 { font-size: font-scale(display, 1); }
h6, .h6 { font-size: font-scale(display, 1); }

@include media-breakpoint-up(md) {
    h1 { font-size: font-scale(display, 6); }
    h2 { font-size: font-scale(display, 5); }
    h3 { font-size: font-scale(display, 4); }
    h4 { font-size: font-scale(display, 3); }
    h5 { font-size: font-scale(display, 2); }
    h6 { font-size: font-scale(display, 1); }
}
