// =============================================================================
// Wrappers
// =============================================================================

.wrapper {
	max-width: map-get($grid-breakpoints, md);
	margin: 0 auto;
	padding: 0 $spacing-l;
}


