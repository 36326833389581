// =============================================================================
// Quotes
// =============================================================================

blockquote {
	margin-left: 0;
	padding-left: 1em;
	border-left: 8px solid #eeeeee;
}

blockquote p {
	margin-bottom: 0.2em;
}

blockquote footer {
	color: #999;
	font-size: font-scale(caption, 2);
}
