// =============================================================================
// Page
// =============================================================================

body {
	background-color: get-color(background, base);
	color: get-color(text, base);
	font-family: font-stack(body);
	font-size: font-scale(body, 2);
	line-height: 1.5;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@include media-breakpoint-up(md) {
	body { font-size: font-scale(body, 3); }
}
